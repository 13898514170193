import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import axios from "axios";
import {
    Button,
    Modal,
    Box,
    TextField,
    Typography,
    Select,
    MenuItem,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import seenIconUrl from '../images/ic_deploy_blue.png';
import bronzeIconUrl from '../images/ic_deploy_bronze.png';
import silverIconUrl from '../images/ic_deploy_silver.png';
import goldIconUrl from '../images/ic_deploy_gold.png';
import clickMarkerUrl from '../images/ic_deploy_marker.png';

const Arenamap = () => {
    const [selectedGym, setSelectedGym] = useState(null);
    const [clickedPosition, setClickedPosition] = useState(null);
    const [gyms, setGyms] = useState([]);
    const [formState, setFormState] = useState({ name: "", arenatype: "bronze", note: "" });
    const [userPosition, setUserPosition] = useState(null);
    const [editingGym, setEditingGym] = useState(null);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const position = [51.1657, 10.4515]; // Mitte von Deutschland


    // Default Icon
    const DefaultIcon = L.icon({
        iconUrl: icon,
        shadowUrl: iconShadow,
        iconAnchor: [12, 41],
        popupAnchor: [0, -41],
    });
    L.Marker.prototype.options.icon = DefaultIcon;

    // Click Marker Icon
    const ClickIcon = L.icon({
        iconUrl: clickMarkerUrl,
        shadowUrl: iconShadow,
        iconAnchor: [40, 70],
        popupAnchor: [0, -110],
    });

    // Beim laden der Seite Arenen laden
    useEffect(() => {
        fetchGyms();
    }, []);

    const handleEditClick = (gym) => {
        setSelectedGym(gym); // Arena setzen
        setFormState({
            gymname: gym.gymname,
            type: gym.type,
            note: gym.note,
        }); // Informationen vorab füllen
    };

    const handleSave = () => {
        editGym(selectedGym.id, formState); // Speichert die Änderungen
        setSelectedGym(null); // Schließt den Dialog
    };

    const handleClose = () => {
        setSelectedGym(null); // Dialog schließen
    };

    const getIconByType = (type) => {
        let iconUrl;
        switch (type) {
            case 'bronze':
                iconUrl = bronzeIconUrl;
                break;
            case 'silber':
                iconUrl = silverIconUrl;
                break;
            case 'gold':
                iconUrl = goldIconUrl;
                break;
            default:
                iconUrl = seenIconUrl;
                break;
        }
        return L.icon({
            iconUrl,
            shadowUrl: iconShadow,
            iconAnchor: [40, 70],
            popupAnchor: [0, -70]
        });
    };

    const ClickMarker = ({ onClick }) => {
        useMapEvents({
            click(e) {
                const target = e.originalEvent.target;

                // Überprüfe, ob der Klick nicht vom Button stammt
                if (!target.closest('.locate-button')) {
                    onClick(e.latlng); // Nur dann den Marker setzen
                }
            }
        });
        return null;
    };

    const LocateButton = () => {
        const map = useMap();

        const handleLocate = (event) => {
            event.preventDefault();
            event.stopPropagation();

            // Fokus auf den Standort des Benutzers
            map.locate().on("locationfound", (e) => {
                map.flyTo(e.latlng, map.getZoom());
            });
        };

        return (
            <Button
                onClick={handleLocate}
                variant="contained"
                sx={{
                    position: "absolute",
                    bottom: "16px",
                    right: "16px",
                    zIndex: 1000,
                    backgroundColor: "white",
                    color: "black",
                }}
            >
                Meine Position
            </Button>
        );
    };

    const handleLocate = (latlng) => {
        setUserPosition(latlng);
    };

    const fetchGyms = async () => {
        try {
            const token = localStorage.getItem('token');

            if (!token) {
                console.error('Kein Token vorhanden');
                return;
            }

            const response = await fetch('https://pokegodex.de/api/users/get-gyms', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                }
            });
            if (response.ok) {
                const data = await response.json();
                setGyms(data);
            } else {
                console.error('Fehler beim Laden der Daten:', await response.text());
            }

        } catch (err) {
            console.error('Error fetching gyms:', err);
        }
    };

    const handleMapClick = (latlng) => {
        setClickedPosition(latlng);
        setEditingGym(null);
        setFormState({ name: '', arenatype: 'bronze', note: '' });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormState({
            ...formState,
            [name]: value
        });
    };

    const handleDeleteGym = async (gymId) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                setSnackbarMessage('Fehler: Nutzer nicht eingeloggt');
                return;
            }

            const response = await fetch(`/api/users/delete-gym?id=${gymId}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                setSnackbarMessage('Arena erfolgreich gelöscht!');
                fetchGyms(); // Liste neu laden
                setEditDialogOpen(false); // Dialog schließen
            } else {
                console.error('Fehler beim Löschen der Arena:', await response.text());
            }
        } catch (error) {
            console.error('Serverfehler:', error.message);
        }
    };

    const editGym = async (gymId, gymInfos) => {
        try {
            const token = localStorage.getItem("token");
            if (!token) {
                setSnackbarMessage("Fehler: Nutzer nicht eingeloggt");
                return;
            }
    
            const response = await fetch(`/api/users/edit-gym`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ gymId, ...gymInfos }),
            });
    
            if (response.ok) {
                fetchGyms(); // Aktualisiert die Liste der Arenen
                setSnackbarMessage("Arena erfolgreich aktualisiert!");
            } else {
                console.error("Fehler beim Bearbeiten der Arena:", await response.text());
            }
        } catch (error) {
            console.error("Serverfehler:", error.message);
        }
    };

    return (
        <div style={{ position: 'relative', height: '100vh', width: '100%' }}>
            {/* Karte */}
            <MapContainer center={position} zoom={17} style={{ height: "100%", width: "100%" }}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                {/* Marker für Arenen */}
                {gyms.map((gym) => (
                    <Marker
                        key={parseInt(gym.id, 10)}
                        position={[gym.latitude, gym.longitude]}
                        icon={getIconByType(gym.type)}
                    >
                        <Popup>
                            <div>
                                <strong>Arena:</strong> {gym.gymname} <br />
                                <strong>Typ:</strong> {gym.type} <br />
                                <strong>Notiz:</strong> {gym.note} <br />
                                <Button
                                    variant="text"
                                    color="primary"
                                    onClick={() => handleEditClick(gym)}
                                >
                                    Bearbeiten
                                </Button>
                            </div>
                        </Popup>
                    </Marker>
                ))}
            </MapContainer>

             {/* Dialog */}
             {selectedGym && (
                <Dialog open={Boolean(selectedGym)} onClose={handleClose} fullWidth>
                    <DialogTitle>Bearbeiten: {selectedGym.gymname}</DialogTitle>
                    <DialogContent>
                        <Box display="flex" flexDirection="column" gap={2}>
                            <TextField
                                label="Name"
                                name="gymname"
                                value={formState.gymname}
                                onChange={handleInputChange}
                                fullWidth
                            />
                            <TextField
                                label="Typ"
                                name="type"
                                value={formState.type}
                                onChange={handleInputChange}
                                fullWidth
                            />
                            <TextField
                                label="Notiz"
                                name="note"
                                value={formState.note}
                                onChange={handleInputChange}
                                multiline
                                rows={4}
                                fullWidth
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <IconButton
                            color="error"
                            onClick={() => {
                                handleDeleteGym(selectedGym.id); // Arena löschen
                                handleClose(); // Dialog schließen
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                        <Button
                            variant="outlined"
                            startIcon={<CancelIcon />}
                            onClick={handleClose}
                        >
                            Abbrechen
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<SaveIcon />}
                            onClick={handleSave}
                        >
                            Speichern
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            {/* Locate-Button */}
            <Button
    onClick={handleLocate}
    sx={{
        position: "absolute",
        bottom: "calc(env(safe-area-inset-bottom) + 25px)",
        right: "18px",
        zIndex: 1000,
        backgroundColor: "white",
        color: "black",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
        },
    }}
    variant="contained"
    startIcon={<GpsFixedIcon />} // Fügt das Icon links vom Text hinzu
>
    Meine Position
</Button>

            {/* Schließen-Button */}
            <IconButton
    onClick={() => navigate("/")}
    sx={{
        position: "absolute",
        top: "16px",
        right: "16px",
        zIndex: 1000,
        color: "red",
        "&:hover": {
            color: "rgba(255, 0, 0, 0.7)", // Blassere Farbe beim Hover
        },
    }}
>
    <HighlightOffIcon sx={{ fontSize: "3rem" }}/>
</IconButton>


        </div>
    );
};

export default Arenamap;