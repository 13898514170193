import React, { useState, useEffect } from "react";
import { ThemeProvider, createTheme, CssBaseline } from "@mui/material";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import BottomNav from "./Components/BottomNav";
import Playerprofile from "./Profile/Playerprofile";
import Arenamap from "./Profile/Arenamap";

const AppContent = ({ themeMode, toggleTheme }) => {
  const location = useLocation();

  // Routen, auf denen die Navigation ausgeblendet wird
  const hideNavOnRoutes = ["/arenamap"];
  const shouldHideNav = hideNavOnRoutes.includes(location.pathname);

  return (
    <>
      {!shouldHideNav && <BottomNav toggleTheme={toggleTheme} themeMode={themeMode} />}
      <Routes>
        <Route path="/" element={<Playerprofile />} />
        <Route path="/arenamap" element={<Arenamap />} />
      </Routes>
    </>
  );
};

const App = () => {
  // Zustand für das Theme (default: hell)
  const [themeMode, setThemeMode] = useState("light");

  // Beim ersten Laden den Zustand aus localStorage laden
  useEffect(() => {
    const savedMode = localStorage.getItem("themeMode") || "light";
    setThemeMode(savedMode);
  }, []);

  // Theme konfigurieren
  const theme = createTheme({
    palette: {
      mode: themeMode,
    },
  });

  // Funktion für das Umschalten des Themes
  const toggleTheme = () => {
    const newMode = themeMode === "light" ? "dark" : "light";
    setThemeMode(newMode);
    localStorage.setItem("themeMode", newMode); // Zustand speichern
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AppContent themeMode={themeMode} toggleTheme={toggleTheme} />
      </Router>
    </ThemeProvider>
  );
};

export default App;
