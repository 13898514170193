import React, { useState } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Home, Search, Map, Settings, Brightness4, Info, Tune } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const BottomNav = ({ toggleTheme, themeMode }) => {
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  // Navigation-Handler
  const handleNavigation = (newValue) => {
    setValue(newValue);
    if (newValue === 0) navigate("/");
    if (newValue === 1) navigate("/search");
    if (newValue === 2) navigate("/arenamap");
  };

  // Popover öffnen/schließen
  const handleSettingsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "settings-popover" : undefined;

  return (
    <>
      {/* Bottom Navigation */}
      <Paper
        elevation={3}
        sx={{
          position: "fixed",
          bottom: 16,
          left: "50%",
          transform: "translateX(-50%)",
          width: "90%",
          borderRadius: 12,
          zIndex: 10,
          backgroundColor: themeMode === "light" ? "#f7f7f7" : "#333",
          backdropFilter: "blur(8px)",
          border: "1px solid rgba(255, 255, 255, 0.3)",
        }}
      >
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => handleNavigation(newValue)}
          showLabels
          sx={{
            backgroundColor: "transparent",
          }}
        >
          <BottomNavigationAction label="Profil" icon={<Home />} />
          <BottomNavigationAction label="Pokedex" icon={<Search />} />
          <BottomNavigationAction label="Map" icon={<Map />} />
          <BottomNavigationAction
            label="Settings"
            icon={<Settings />}
            onClick={handleSettingsClick} // Öffnet das Popover
          />
        </BottomNavigation>
      </Paper>

      {/* Popover für "Settings" */}
      <Popover
  id={id}
  open={open}
  anchorEl={anchorEl}
  onClose={handleSettingsClose}
  anchorOrigin={{
    vertical: "top",
    horizontal: "center",
  }}
  transformOrigin={{
    vertical: "bottom",
    horizontal: "center",
  }}
  slotProps={{
    paper: {
      sx: {
        borderRadius: 12, // Abrundung der Ecken
        marginBottom: "1px", // Verschiebt die Box um 1px nach oben
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", // Dezenter Schatten
        border: "1px solid rgba(255, 255, 255, 0.3)",
        backgroundColor: themeMode === "light" ? "#f7f7f7" : "#333",
        overflow: "hidden",
      },
    },
  }}
>
  <List>
    {/* Hell/Dunkel-Modus */}
    <ListItem button onClick={toggleTheme}>
      <ListItemIcon>
        <Brightness4 />
      </ListItemIcon>
      <ListItemText primary={themeMode === "light" ? "Dark" : "Light"} />
    </ListItem>

    {/* About */}
    <ListItem button>
      <ListItemIcon>
        <Info />
      </ListItemIcon>
      <ListItemText primary="About" />
    </ListItem>

    {/* Weitere Einstellungen */}
    <ListItem button>
      <ListItemIcon>
        <Tune />
      </ListItemIcon>
      <ListItemText primary="Settings" />
    </ListItem>
  </List>
</Popover>
    </>
  );
};

export default BottomNav;
